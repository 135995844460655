<a routerLink="../">&lt; back</a>

<h1>Projects</h1>

<h2>Web App: Documentations for roof work</h2>
<div>
  <dl>
    <dt>Project</dt>
    <dd>Employer: Pemedia, Customer: SECRET</dd>

    <dt>Description</dt>
    <dd>SECRET</dd>

    <dt>Tech Stack</dt>
    <dd>React, TypeScript, StyledComponents, REST API</dd>

    <dt>My Role</dt>
    <dd>Frontend Developer</dd>
  </dl>
</div>

<h2>svg-gen</h2>
<div>
  <dl>
    <dt>Project</dt>
    <dd>Private idea, Open Source</dd>

    <dt>Description</dt>
    <dd>
      A library to create, manipulate and animate svg via TypeScript
      (recommended) or JavaScript.
    </dd>

    <dt>Tech Stack</dt>
    <dd>TypeScript, SVG, NPM Package Deployment</dd>
  </dl>
</div>
